@mixin clearfix(){
	&:before, &:after {
	  content: " ";
	  display: table;
	}
  
	&:after {
	  clear: both;
	}
  }
  
  @mixin round-corner($radius){
	border-radius: $radius;
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
  }
  
  @mixin round-right-corners($radius){
	border-top-right-radius: $radius;
	-webkit-border-top-right-radius: $radius;
	-moz-border-top-right-radius: $radius;
  
	border-bottom-right-radius: $radius;
	-webkit-border-bottom-right-radius: $radius;
	-moz-border-bottom-right-radius: $radius;
  }
  
  @mixin round-left-corners($radius){
	border-top-left-radius: $radius;
	-webkit-border-top-left-radius: $radius;
	-moz-border-top-left-radius: $radius;
  
	border-bottom-left-radius: $radius;
	-webkit-border-bottom-left-radius: $radius;
	-moz-border-bottom-left-radius: $radius;
  }
  
  @mixin round-top-corners($radius){
	border-top-left-radius: $radius;
	-webkit-border-top-left-radius: $radius;
	-moz-border-top-left-radius: $radius;
  
	border-top-right-radius: $radius;
	-webkit-border-top-right-radius: $radius;
	-moz-border-top-right-radius: $radius;
  }
  
  @mixin round-top-left-corner($radius){
	border-top-left-radius: $radius;
	-webkit-border-top-left-radius: $radius;
	-moz-border-top-left-radius: $radius;
  }
  
  @mixin round-top-right-corner($radius){
	border-top-right-radius: $radius;
	-webkit-border-top-right-radius: $radius;
	-moz-border-top-right-radius: $radius;
  }
  
  @mixin round-bottom-corners($radius){
	border-bottom-left-radius: $radius;
	-webkit-border-bottom-left-radius: $radius;
	-moz-border-bottom-left-radius: $radius;
  
	border-bottom-right-radius: $radius;
	-webkit-border-bottom-right-radius: $radius;
	-moz-border-bottom-right-radius: $radius;
  }
  
  @mixin round-bottom-left-corner($radius){
	border-bottom-left-radius: $radius;
	-webkit-border-bottom-left-radius: $radius;
	-moz-border-bottom-left-radius: $radius;
  }
  
  @mixin round-bottom-right-corner($radius){
	border-bottom-right-radius: $radius;
	-webkit-border-bottom-right-radius: $radius;
	-moz-border-bottom-right-radius: $radius;
  }
  
  @mixin blur($radius){
	filter: blur($radius);
	-webkit-filter: blur($radius);
	-moz-filter: blur($radius);
	-o-filter: blur($radius);
	-ms-filter: blur($radius);
  }
  
  @mixin rotate($angle){
	transform: rotate($angle);
	-webkit-transform: rotate($angle);
	-moz-transform: rotate($angle);
	-o-transform: rotate($angle);
	-ms-transform: rotate($angle);
  }
  
  @mixin transition($duration, $timing){
	transition: $duration $timing;
	-webkit-transition: $duration $timing;
	-moz-transition: $duration $timing;
	-o-transition: $duration $timing;
  }
  
  @mixin unload-rendering {
	transform: translateZ(0);
	-webkit-transform: translateZ(0);
	-moz-transform: translateZ(0);
	-o-transform: translateZ(0);
	-ms-transform: translateZ(0);
  }
  
  @mixin float-clear-width {
	float: left;
	clear: both;
	width: 100%;
  }
  
  @mixin animation($animate...) {
	  $max: length($animate);
	  $animations: '';
  
	  @for $i from 1 through $max {
		  $animations: #{$animations + nth($animate, $i)};
  
		  @if $i < $max {
			  $animations: #{$animations + ", "};
		  }
	  }
	  -webkit-animation: $animations;
	  -moz-animation:    $animations;
	  -o-animation:      $animations;
	  animation:         $animations;
  }
  
  @mixin keyframes($animationName) {
	  @-webkit-keyframes #{$animationName} {
		  @content;
	  }
	  @-moz-keyframes #{$animationName} {
		  @content;
	  }
	  @-o-keyframes #{$animationName} {
		  @content;
	  }
	  @keyframes #{$animationName} {
		  @content;
	  }
  }
  
  @mixin box-shadow($top, $left, $blur, $color, $inset: false) {
	@if $inset {
	  -webkit-box-shadow:inset $top $left $blur $color;
	  -moz-box-shadow:inset $top $left $blur $color;
	  box-shadow:inset $top $left $blur $color;
	} @else {
	  -webkit-box-shadow: $top $left $blur $color;
	  -moz-box-shadow: $top $left $blur $color;
	  box-shadow: $top $left $blur $color;
	}
  }
  
  @mixin disable-select {
	-webkit-user-select: none;  
	-moz-user-select: none;    
	-ms-user-select: none;      
	user-select: none;
  }
  
  @mixin gradient($direction, $startColor, $endColor){
	background: $startColor;
	background: -webkit-linear-gradient($direction, $startColor, $endColor);
	background: -o-linear-gradient($direction, $startColor, $endColor);
	background: -moz-linear-gradient($direction, $startColor, $endColor);
	background: linear-gradient(to $direction, $startColor, $endColor);
  }
  
  @mixin reset-font-lnum {
	font-variant-numeric: lining-nums;
	  -moz-font-feature-settings:"lnum" 1; 
	  -moz-font-feature-settings:"lnum=1"; 
	  -ms-font-feature-settings:"lnum" 1; 
	  -o-font-feature-settings:"lnum" 1; 
	  -webkit-font-feature-settings:"lnum" 1; 
	  font-feature-settings:"lnum" 1;
  }
  