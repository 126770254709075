// Colours
$blue-grey			: #8c9fbf;
$dark-green			: #589958;
$dark-grey			: #404041;
$faint-grey			: #c7c7c8;
$hover-grey			: #e4e7e7;
$hover-light-grey	: #f8f8f8;
$light-grey			: #d8d9d9;
$lightest-grey		: #ececec;
$mystery-grey		: #8c8c8e;
$subtle-green		: #d5e5d5;
$white-grey			: #f8f8f8;
$blue				: #02b7e2;
$green				: #43ac6a;
$orange				: #e78d2d;
$purple				: #b69deb;
$red				: #e2001a;
$tan				: #d0c6b1;
$white				: #FFFFFF;
$yellow				: #fff600;


// Colour Scheme
$background-color 	: $white-grey;
$body-font-color	: $dark-grey;
$body-font-light-color: $mystery-grey;
$line-color			: $lightest-grey;
